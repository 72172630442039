import { useTranslation } from 'next-i18next';
import { FC } from 'react';

export const Copyright: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-500">
      <div className="container-base px-4 py-0.5 md:px-0">
        <p className="text-xs font-light text-black-800">{t('footer.copy')},{new Date().getFullYear()}</p>
      </div>
    </div>
  );
};
