import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { GOOGLE_MAPS_LINK, PHONE, PHONE_HREF } from '../../../../config';

import { Icons, IconsTypes } from '@/components/shared/Icons';
import { Link, LinkProps } from '@/components/shared/Link';

interface IFooterAddressItem extends LinkProps {
  iconName: IconsTypes;
  label: string;
}

export const Contacts: FC = () => {
  const { t } = useTranslation();

  const addressItems: IFooterAddressItem[] = [
    {
      href: GOOGLE_MAPS_LINK,
      target: '_blank',
      iconName: IconsTypes.LOCATION,
      label: t('shared.location.address'),
    },
    {
      href: PHONE_HREF,
      iconName: IconsTypes.PHONE,
      label: PHONE,
    },
  ];

  return (
    <ul className="w-52 space-y-6 lg:space-y-8">
      {addressItems.map(({ iconName, label, href, target = undefined }) => (
        <li key={iconName}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Link href={href} {...(target && { target })} className="flex w-full space-x-4">
            <div className="[&>svg]:h-[25px] [&>svg]:w-[25px]">{Icons({ name: iconName })}</div>
            <p className="text-white md:text-base-xl">{label}</p>
          </Link>
        </li>
      ))}
    </ul>
  );
};
