import { Config } from 'tailwindcss';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../tailwind.config';

const { theme = {} as NonNullable<Config['theme']> } = resolveConfig(tailwindConfig);

export const SUPPORTED_TAILWIND_LAYOUT_SIZES = ['sm', 'md', 'lg'] as const;
export type SupportedLayoutSize = (typeof SUPPORTED_TAILWIND_LAYOUT_SIZES)[number];
export const screens = (theme.screens as Record<SupportedLayoutSize, string>) || {};
export const getMediaQuery = (breakpoint: SupportedLayoutSize): string => `(min-width: ${screens?.[breakpoint]})`;
