const colors = require('tailwindcss/colors');

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    fontFamily: {
      sans: ['var(--font-mulish)', 'sans-serif'],
    },
    screens: {
      sm: '375px',
      md: '744px',
      lg: '1024px',
      xl: '1440px',
      '2xl': '1536px',
    },
    extend: {
      width: {
        66: '16.5rem',
      },
      spacing: {
        3.5: '0.875rem',
        4.5: '1.125rem',
        9.5: '2.375rem',
        13: '3.25rem',
        15: '3.75rem',
        18: '4.5rem',
        40: '9.375rem',
      },
      borderWidth: {
        0.5: '0.5px',
        unset: 'unset',
      },
      fontSize: {
        sm: ['12px', '18px'],
        base: ['14px', '21px'],
        'base-sm': ['14px', '24px'],
        'base-md': ['16px', '24px'],
        'base-lg': ['18px', '27px'],
        'base-xl': ['18px', '24px'],
        md: ['18px', '22px'],
        lg: ['22px', '24px'],
        xl: ['24px', '29px'],
        '2xl': ['24px', '38px'],
        '3xl': ['28px', '36px'],
        '4xl': ['40px', '46px'],

        'h2-sm': ['24px', '46px'],
        'h2-md': ['40px', '46px'],
        'h3-sm': ['22px', '30px'],
        'h3-md': ['28px', '30px'],
        'h4-sm': ['18px', '23px'],
        'h4-md': ['28px', '36px'],
      },
      colors: {
        green: {
          DEFAULT: '#247E80',
          500: '#247E80',
          600: '#354Е49',
          200: '#3C9D9F',
          100: '#3C9FA1',
        },
        white: colors.white,
        black: {
          DEFAULT: '#000000',
          900: '#0D0D0D',
          800: '#1E2222',
          700: '#272829',
          600: '#323642',
          500: '#282D27',
          400: '#354349',
        },
        gray: {
          900: '#E0E4EF',
          800: '#EAEAEA',
          700: '#F8F8FB',
          600: '#ADADAD',
          500: '#E5E5E5',
        },
      },
      boxShadow: {
        xl: '0px 2px 6px rgba(53, 78, 73, 0.2)',
      },
      borderRadius: {
        3: '12px',
        '4xl': '35px',
      },
    },
  },
  plugins: [],
};
