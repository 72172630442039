import { animated, useSpring } from '@react-spring/web';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import { Icons, IconsTypes } from '@/components/shared/Icons';
import { useScroll } from '@/hooks/useScroll';

interface IDropdownProps {
  open: boolean;
  setOpen: () => void;
  switcher: ReactNode;
  dropdown: ReactNode;
  className?: string;
  icon?: ReactNode;
}

export const Dropdown: FC<IDropdownProps> = ({
  open,
  setOpen,
  switcher,
  dropdown,
  className,
  icon = Icons({ name: IconsTypes.ARROW_DOWN, iconProps: { color: 'currentColor' } }),
}) => {
  const [isDropdownReachable, setDropdownReachable] = useState(false);
  const [switcherContent, setSwitcherContent] = useState<ReactNode>(null);

  const onBackdropScroll = useCallback(() => {
    if (open) {
      setOpen();
    }
  }, [open, setOpen]);

  useScroll(onBackdropScroll);

  useEffect(() => {
    if (window) {
      setSwitcherContent(switcher);
    }
  }, [switcher]);

  const dropdownStyles = useSpring({
    transform: open ? 'translate3D(0, 0px, 0)' : `translate3D(0,-20px ,0)`,
    opacity: Number(open || 0),
    onStart: () => {
      setDropdownReachable(true);
    },
    onRest: {
      opacity: (opacity) => {
        if (opacity.value === 0) setDropdownReachable(false);
      },
    },
  });

  const iconStyles = useSpring({
    transform: open ? `rotateX(180deg)` : `rotateX(0deg)`,
  });

  return (
    <>
      <div className={className}>
        <button className="flex items-center space-x-1 font-semibold" onClick={setOpen}>
          <span>{switcherContent}</span>
          <animated.span style={iconStyles} className="mt-[4px]">
            {icon}
          </animated.span>
        </button>

        <animated.div style={dropdownStyles} className={`z-[1] pt-3.5 ${isDropdownReachable ? 'absolute' : 'hidden'}`}>
          {dropdown}
        </animated.div>
      </div>

      {open && <div className="fixed left-0 top-0 h-screen w-screen" onClick={setOpen} />}
    </>
  );
};
