import { FC, HTMLAttributes } from 'react';

import { FACEBOOK_LINK, INSTAGRAM_LINK } from '../../../../config';

import { Icons, IconsTypes } from '@/components/shared/Icons';
import { Link } from '@/components/shared/Link';

interface IFooterSocialItem {
  link: string;
  iconName: IconsTypes;
}

const socials: IFooterSocialItem[] = [
  { link: INSTAGRAM_LINK, iconName: IconsTypes.INSTAGRAM },
  { link: FACEBOOK_LINK, iconName: IconsTypes.FACEBOOK },
];

export const Socials: FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => (
  <div className={`flex items-center space-x-2 sm:space-x-4 md:space-x-7 ${className}`}>
    {socials.map(({ link, iconName }) => (
      <Link key={link} href={link} aria-label={link} target="_blank">
        {Icons({ name: iconName })}
      </Link>
    ))}
  </div>
);
