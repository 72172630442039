import { keys } from './common';

const imageExtensionsRegex: Record<string, RegExp> = {
  jpeg: /\.(jpe?g|jfif|pjpeg|pjp)$/im,
  png: /\.(png)$/im,
  webp: /\.(webp)$/im,
};

export const getImageType = (url: string): string => {
  const type: string = keys<string>(imageExtensionsRegex).find((key) => imageExtensionsRegex[key].test(url)) ?? 'jpeg';
  return `image/${type}`;
};
