import { ParsedUrlQuery } from 'querystring';

import { useRouter } from 'next/router';
// import { useEffect } from 'react';

// import nextI18nextConfig from '../../next-i18next.config';

import { useLocale } from './useLocale';

import languageDetector from '@/helpers/languageDetector';
import { getLocalizedRoute } from '@/helpers/redirect';
import { Locale } from '@/types';

export const LOCALES_LIST: Record<Locale, string> = {
  ua: 'УКР',
  en: 'EN',
};

const WITHOUT_EN_PAGES: string[] = [
  '/seasons',
  '/arboretum',
  '/greenhouse-exposition',
  '/greenhouses',
  '/expositions',
  '/historical-monuments-and-art-objects',
  '/interactive-map',
];

export function useLanguageSwitcher() {
  const router = useRouter();
  const { currentLocale } = useLocale();

  const handleSwitchLocale = (locale: string) => languageDetector.cache?.(locale);

  const createHref = (locale: Locale) => {
    const { pathname: routerPathname, asPath: routerAsPath, query: routerQuery } = router;
    const withoutEnVersion: boolean =
      routerAsPath?.startsWith('/plant') || WITHOUT_EN_PAGES.some((slug) => routerAsPath.endsWith(slug));

    const pathname: string = withoutEnVersion ? '/' : routerPathname;
    // const asPath: string = withoutEnVersion ? '/' : routerAsPath;
    const query: ParsedUrlQuery = withoutEnVersion ? {} : routerQuery;

    return getLocalizedRoute({ query, pathname: pathname?.endsWith('/') ? pathname : pathname + '/' }, locale);
  };

  return {
    handleSwitchLocale,
    createHref,
    currentLocale: LOCALES_LIST?.[currentLocale],
    locales: LOCALES_LIST,
  };
}
