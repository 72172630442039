import { ImageProps } from 'next/image';
import { FC, PropsWithChildren } from 'react';

import { Footer } from '@/components/sections/Footer';
import { Header } from '@/components/sections/Header';
import { Banner } from '@/components/shared/Banner';
import { SEOBlock } from '@/components/shared/SEOBlock';
import { SiteMenuItem, ISEOOptions, IBanner } from '@/types';

interface ILayoutProps {
  siteMenu: SiteMenuItem[];
  seoOptions: ISEOOptions;
  banner?: IBanner & ImageProps;
}

export const Layout: FC<PropsWithChildren<ILayoutProps>> = ({ siteMenu, seoOptions, children, banner = undefined }) => (
  <>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <SEOBlock {...seoOptions} />

    <main className="bg-gray-700">
      <Header siteMenu={siteMenu} />

      <div className={banner ? "pt-20 md:pt-0" : ""}>
        {banner ? <Banner banner={banner} /> : null}

        {children}
      </div>

      <Footer />
    </main>
  </>
);
