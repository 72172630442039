import { FC } from 'react';

import { Contacts } from './Contacts';
import { Copyright } from './Copyright';
import { FooterMenu } from './FooterMenu';
import { Honor } from './Honor';
import { Socials } from './Socials';

import { Logo } from '@/components/shared/Logo';

export const Footer: FC = () => {
  return (
    <>
      <div className="relative z-10 bg-black-600">
        <div className="container flex justify-between text-white md:py-8">
          <div className="grow md:grow-0">
            <div className="flex items-center justify-between">
              <Logo isLight />
              <Socials className="md:hidden" />
            </div>

            <hr className="my-4 md:my-6 lg:w-72 xl:w-96" />

            <div className="flex items-center justify-between space-x-4">
              <Honor />
              <FooterMenu className="md:hidden" />
            </div>
          </div>

          <FooterMenu className="hidden md:block" />

          <div className="hidden space-y-6 md:block lg:space-y-8">
            <Socials />
            <Contacts />
          </div>
        </div>
      </div>

      <Copyright />
    </>
  );
};
