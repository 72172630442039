import { ButtonHTMLAttributes } from 'react';

import { Colors } from '@/types/style';

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Colors | string;
}

export function Button({ variant = Colors.primary, ...buttonProps }: IButton) {
  const buttonTheme = {
    [variant]: variant,
    [Colors.primary]: 'text-white bg-green hover:bg-green-100',
    [Colors.secondary]: 'text-green-600 bg-gray-900 hover:shadow-xl',
    [Colors.transparent]: 'text-green hover:text-green-100 bg-transparent',
    [Colors.black]: 'text-white bg-black-600 hover:text-black-600 hover:bg-white hover:shadow-xl',
    [Colors.rounded]: 'bg-white rounded-full hover:shadow-xl h-9 w-9 flex items-center justify-center !py-0 !px-0',
  }?.[variant];

  const buttonClassName = `py-2 px-6 rounded-4xl text-base-sm md:text-base-md xl:text-base-xl transition all duration-500  ${buttonTheme} ${
    buttonProps?.className ?? ''
  }`;
  return <button {...buttonProps} className={buttonClassName} />;
}
