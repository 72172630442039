import { FC } from 'react';

import { LocalesDropdown } from './LocalesDropdown';

import { Button } from '@/components/shared/Button';
import { Icons, IconsTypes } from '@/components/shared/Icons';
import { Link } from '@/components/shared/Link';
import { formatLink, formatPathname } from '@/helpers/routing';
import { Colors, DropdownCategories, HeaderDropdowns, HeaderDropdownsState, SiteMenuItem } from '@/types';

interface IDrawerMenuProps {
  open: boolean;
  toggleOpen: () => void;
  siteMenu: SiteMenuItem[];
  expandedState: HeaderDropdownsState;
  toggleDropdown: (dropdownKey: HeaderDropdowns) => void;
}

export const DrawerMenu: FC<IDrawerMenuProps> = ({ open, toggleOpen, siteMenu, expandedState, toggleDropdown }) => {
  const setOpenLocalesDropdown = () => {
    const expanded = expandedState[DropdownCategories.LanguageSwitcher];

    toggleDropdown(DropdownCategories.LanguageSwitcher);

    if (expanded) {
      toggleOpen();
    }
  };

  return (
    <div
      className={`fixed top-0 z-50 flex h-screen w-screen flex-col items-end overflow-y-auto bg-black-600 pb-12 pl-9 pr-9 pt-6 transition-[left] duration-500 sm:pl-16 lg:hidden ${
        open ? 'left-0' : 'left-[100%]'
      }`}
    >
      <Button type="button" variant={Colors.transparent} onClick={toggleOpen}>
        {Icons({ name: IconsTypes.CLOSE, iconProps: { className: 'text-white' } })}
      </Button>

      <LocalesDropdown
        open={expandedState[DropdownCategories.LanguageSwitcher]}
        setOpen={setOpenLocalesDropdown}
        className="p-4 text-white"
      />

      <nav className="w-full sm:px-4">
        <ul className="mb-4 pb-4 text-white">
          {siteMenu.map(({ name, slug, dropdown }) => (
            <li key={slug} className="py-2 text-base-lg md:py-4 md:text-xl">
              {!!dropdown.length ? (
                <>
                  <button className="text-left" onClick={() => toggleDropdown(slug)}>
                    {name}
                  </button>

                  <ul
                    className={`w-full max-w-xs overflow-hidden pl-7 transition-[max-height] duration-500 md:w-80 ${
                      expandedState[slug] ? 'max-h-[100vh]' : 'max-h-0'
                    }`}
                  >
                    {dropdown.map(({ menu_order, name, page_slug, blog_slug }) => (
                      <li key={menu_order} className="py-2 text-base-md md:py-4 md:text-lg">
                        <Link href={formatLink({ page_slug, blog_slug })} onClick={toggleOpen}>
                          {name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <Link href={formatPathname(slug)} className="font-semibold" onClick={toggleOpen}>
                  {name}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};
