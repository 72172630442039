import { useTranslation } from 'next-i18next';
import { FC, HTMLAttributes } from 'react';

import { Link } from '@/components/shared/Link';
import { formatPathname } from '@/helpers/routing';
import { useFooterMenu } from '@/hooks/useFooterMenu';

export const FooterMenu: FC<HTMLAttributes<HTMLUListElement>> = ({ className }) => {
  const { t } = useTranslation();
  const { footerMenu } = useFooterMenu();

  return (
    <nav className={className}>
      <ul>
        {footerMenu.map(({ translationKey, slug }) => (
          <li key={slug} className="py-1 text-base font-bold text-white md:py-2 md:text-base-xl">
            <Link href={formatPathname(slug)} className="block w-28 lg:w-60">
              {t(translationKey)}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};
