import { Locales } from '../../../config';

import { toIsoLocale } from '@/hooks/useLocale';
import { Locale } from '@/types';

type Exception = {
  path: string;
  locale: Locale[];
};

const EXCEPTIONS: Exception[] = [
  {
    path: '/plant/',
    locale: [Locale.EN],
  },
];

type LanguageAlternates = { hrefLang: string; href: string };

export default function getLanguageAlternates(
  url: string,
  locales: Locale[] = Locales as Locale[],
  exceptions: Exception[] = EXCEPTIONS,
): LanguageAlternates[] {
  const appliedExceptionToUrl = exceptions.filter((exception) => url.includes(exception.path));

  return locales
    .filter((locale) => !appliedExceptionToUrl.some((exception) => exception.locale.includes(locale)))
    .map((locale) => ({
      hrefLang: toIsoLocale(locale),
      href: url.replace(new RegExp(`/(${locales.join('|')})/`), `/${locale}/`),
    }));
}
