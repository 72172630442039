import dynamic from 'next/dynamic';

import { IconProps } from './Icons';

export enum IconsTypes {
  CLOSE,
  ARROW_DOWN,
  LOCATION,
  MENU,
  PHONE,
  LOGO,
  LOGO_EN,
  LOGO_SMALL,
  FACEBOOK,
  INSTAGRAM,
  PIN,
  CLOCK,
  QRCODE,
  EXPAND,
  ARROW_LEFT,
  ARROW_RIGHT,
  WOMBAT,
  BUS,
  CAR,
  BICYCLE,
  TICKET,
  DASHED_DIVIDER,
  PATH_BORDERS,
  EQUIPMENT_PLACING,
  CRAMPED_GREENHOUSE,
  MANAGEMENT_INSTRUCTIONS,
  ROSE,
  FOREST,
  BUSH,
  BUSH_WITH_BERRIES,
  SALLOW,
  DENDROLOGY_TREE,
  RED_FLOWER,
  RED_FLOWERS,
  SEEDLING,
  GREEN_LEAFS,
  TULIP,
  GREEN_FLOWERS,
  BERRIES,
  NATURAL_STATUS,
  LONG_LIVING,
  HISTORY_MEANING,
  BENEFITS,
  ENDANGERED,
  MEDICAL,
  MINIATURE,
  CEREALS,
  TROPICAL,
  SPORE_PLANT,
  SUCCULENTS,
  AROIDS,
  LOCATION_PIN,
  NOT_FOUND,
}

export type { IconProps } from './Icons';

export interface IIcon {
  name: IconsTypes;
  iconProps?: IconProps;
}
const LoadableIcon = (name: string) => dynamic<IconProps>(() => import(`./${name}`), { ssr: false });

export function Icons({ name, iconProps = {} }: IIcon) {
  const Icon = {
    [IconsTypes.CLOSE]: LoadableIcon('Close'),
    [IconsTypes.ARROW_DOWN]: LoadableIcon('ArrowDown'),
    [IconsTypes.LOCATION]: LoadableIcon('Location.svg'),
    [IconsTypes.MENU]: LoadableIcon('Menu.svg'),
    [IconsTypes.PHONE]: LoadableIcon('Phone.svg'),
    [IconsTypes.LOGO]: LoadableIcon('Logo.svg'),
    [IconsTypes.LOGO_EN]: LoadableIcon('LogoEn.svg'),
    [IconsTypes.LOGO_SMALL]: LoadableIcon('LogoSmall.svg'),
    [IconsTypes.FACEBOOK]: LoadableIcon('Facebook.svg'),
    [IconsTypes.INSTAGRAM]: LoadableIcon('Instagram.svg'),
    [IconsTypes.PIN]: LoadableIcon('Pin.svg'),
    [IconsTypes.CLOCK]: LoadableIcon('Clock.svg'),
    [IconsTypes.QRCODE]: LoadableIcon('QRCode.svg'),
    [IconsTypes.EXPAND]: LoadableIcon('Expand.svg'),
    [IconsTypes.ARROW_LEFT]: LoadableIcon('ArrowLeft.svg'),
    [IconsTypes.ARROW_RIGHT]: LoadableIcon('ArrowRight'),
    [IconsTypes.WOMBAT]: LoadableIcon('GoWombatLogo.svg'),
    [IconsTypes.BUS]: LoadableIcon('Bus.svg'),
    [IconsTypes.CAR]: LoadableIcon('Car.svg'),
    [IconsTypes.BICYCLE]: LoadableIcon('Bicycle.svg'),
    [IconsTypes.TICKET]: LoadableIcon('Ticket'),
    [IconsTypes.DASHED_DIVIDER]: LoadableIcon('DashedDivider.svg'),
    [IconsTypes.PATH_BORDERS]: LoadableIcon('PathBorders.svg'),
    [IconsTypes.EQUIPMENT_PLACING]: LoadableIcon('EquipmentPlacing.svg'),
    [IconsTypes.CRAMPED_GREENHOUSE]: LoadableIcon('CrampedGreenhouse.svg'),
    [IconsTypes.MANAGEMENT_INSTRUCTIONS]: LoadableIcon('ManagementInstructions.svg'),
    [IconsTypes.DENDROLOGY_TREE]: LoadableIcon('DendrologyTree.svg'),
    [IconsTypes.FOREST]: LoadableIcon('Forest.svg'),
    [IconsTypes.BUSH]: LoadableIcon('Bush.svg'),
    [IconsTypes.BUSH_WITH_BERRIES]: LoadableIcon('BushWithBerries.svg'),
    [IconsTypes.SALLOW]: LoadableIcon('Sallow.svg'),
    [IconsTypes.ROSE]: LoadableIcon('Rose.svg'),
    [IconsTypes.RED_FLOWER]: LoadableIcon('RedFlower.svg'),
    [IconsTypes.RED_FLOWERS]: LoadableIcon('RedFlowers.svg'),
    [IconsTypes.SEEDLING]: LoadableIcon('Seedling.svg'),
    [IconsTypes.GREEN_LEAFS]: LoadableIcon('GreenLeafs.svg'),
    [IconsTypes.TULIP]: LoadableIcon('Tulip.svg'),
    [IconsTypes.GREEN_FLOWERS]: LoadableIcon('GreenFlowers.svg'),
    [IconsTypes.BERRIES]: LoadableIcon('Berries.svg'),
    [IconsTypes.NATURAL_STATUS]: LoadableIcon('NaturalStatus.svg'),
    [IconsTypes.LONG_LIVING]: LoadableIcon('LongLiving.svg'),
    [IconsTypes.HISTORY_MEANING]: LoadableIcon('HistoryMeaning.svg'),
    [IconsTypes.BENEFITS]: LoadableIcon('Benefits.svg'),
    [IconsTypes.ENDANGERED]: LoadableIcon('Endangered.svg'),
    [IconsTypes.MEDICAL]: LoadableIcon('Medical.svg'),
    [IconsTypes.MINIATURE]: LoadableIcon('Miniature.svg'),
    [IconsTypes.CEREALS]: LoadableIcon('Cereals.svg'),
    [IconsTypes.TROPICAL]: LoadableIcon('Tropical.svg'),
    [IconsTypes.SPORE_PLANT]: LoadableIcon('SporePlant.svg'),
    [IconsTypes.SUCCULENTS]: LoadableIcon('Succulents.svg'),
    [IconsTypes.AROIDS]: LoadableIcon('Aroids.svg'),
    [IconsTypes.LOCATION_PIN]: LoadableIcon('LocationPin'),
    [IconsTypes.NOT_FOUND]: LoadableIcon('NotFound.svg'),
  }[name];

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Icon {...iconProps} />;
}
