import { FC } from 'react';

import { Dropdown } from '@/components/shared/Dropdown';
import { Link } from '@/components/shared/Link';
import { formatLink } from '@/helpers/routing';
import { ISiteMenuDropdown } from '@/types';

interface IDesktopMenuDropdownProps {
  open: boolean;
  setOpen: () => void;
  name: string;
  subItems: ISiteMenuDropdown[];
}

export const DesktopMenuDropdown: FC<IDesktopMenuDropdownProps> = ({ open, setOpen, name, subItems }) => (
  <Dropdown
    open={open}
    setOpen={setOpen}
    switcher={name}
    dropdown={
      <ul className="rounded-sm bg-white">
        {subItems.map(({ menu_order, name, page_slug, blog_slug }) => (
          <li key={menu_order}>
            <Link
              href={formatLink({ page_slug, blog_slug })}
              className="block max-w-[200px] px-4 py-3 text-base-md font-semibold text-black"
            >
              {name}
            </Link>
          </li>
        ))}
      </ul>
    }
  />
);
