import { FC } from 'react';

import { IBanner } from '@/types';

interface BannerProps {
  banner: IBanner;
}

export const Banner: FC<BannerProps> = ({ banner }) => {
  return (
    <picture className="h-60 md:h-[442px]">
      <source media="(max-width: 450px)" srcSet={banner?.small} />
      <source media="(max-width: 893px)" srcSet={banner?.medium} />
      <source media="(max-width: 1728px)" srcSet={banner?.large} />
      <img className="h-full w-full object-cover" src={banner.src} alt={banner.alt} />
    </picture>
  );
};
