import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { FC, HTMLProps, useMemo } from 'react';

import { detectLanguageFromPath } from '../../../helpers/languageDetector';

export type LinkProps = NextLinkProps & Omit<HTMLProps<HTMLAnchorElement>, 'ref'>;

export const Link: FC<LinkProps> = ({ children, ...rest }) => {
  const router = useRouter();
  const locale: string = rest.locale || (router.query.locale as string) || '';

  const url = useMemo(() => {
    const href = rest.href || router.asPath;
    if (typeof href != 'string') return href;

    if ((href as string)?.indexOf('http') === 0) {
      return href;
    }

    if (detectLanguageFromPath(href)) {
      return `/${locale}${href}`;
    }

    if (locale) {
      return href
        ? `/${locale}${href}`
        : Object.keys(router.query).reduce((href: string, param: string) => {
            return href.replace(`[${param}]`, router.query[param] as string);
          }, router.pathname || '');
    }

    return href;
  }, [locale, rest.href, router.asPath, router.pathname, router.query]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <NextLink {...rest} href={url}>
      {children}
    </NextLink>
  );
};

export default Link;
