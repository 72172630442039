import { useMemo } from 'react';

import { useLocale } from './useLocale';

interface IFooterMenuItem {
  translationKey: string;
  slug: string;
}

interface IUseFooterMenu {
  footerMenu: IFooterMenuItem[];
}

const aboutUs: IFooterMenuItem = {
  translationKey: 'footer.menu.about-us',
  slug: 'about-us',
};

const infoForVisitors: IFooterMenuItem = {
  translationKey: 'footer.menu.info-for-visitors',
  slug: 'plan-your-visit',
};

export const useFooterMenu = (): IUseFooterMenu => {
  const { isEnLocale } = useLocale();

  const footerMenu = useMemo<IFooterMenuItem[]>(
    () => [aboutUs, infoForVisitors],
    [isEnLocale],
  );

  return { footerMenu };
};
