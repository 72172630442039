import { useEffect } from 'react';

export const useScroll = (listener: () => void): void => {
  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', listener);
    }

    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, [listener]);
};
