import { useTranslation } from 'next-i18next';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { DrawerMenu } from './DrawerMenu';
import { HeaderMenu } from './HeaderMenu';
import { LocalesDropdown } from './LocalesDropdown';

import { Button } from '@/components/shared/Button';
import { Icons, IconsTypes } from '@/components/shared/Icons';
import { Link } from '@/components/shared/Link';
import { Logo } from '@/components/shared/Logo';
import { keys } from '@/helpers/common';
import { getMediaQuery } from '@/helpers/getMediaQuery';
import { useScroll } from '@/hooks/useScroll';
import { useToggle } from '@/hooks/useToggle';
import { DropdownCategories, HeaderDropdowns, HeaderDropdownsState, SiteMenuItem } from '@/types';

interface IHeaderProps {
  siteMenu: SiteMenuItem[];
}

const getInitialExpandedState = (
  siteMenu: SiteMenuItem[],
  prevExpandedState: HeaderDropdownsState,
): HeaderDropdownsState => siteMenu.reduce((acc, { slug }) => ({ ...acc, [slug]: false }), prevExpandedState);

export const Header: FC<IHeaderProps> = ({ siteMenu }) => {
  const { t } = useTranslation();

  const [isMenuOpen, toggleMenuOpen] = useToggle();

  const [expandedState, setExpandedState] = useState<HeaderDropdownsState>({
    [DropdownCategories.LanguageSwitcher]: false,
  } as HeaderDropdownsState);

  useEffect(() => {
    setExpandedState((prevState) => getInitialExpandedState(siteMenu, prevState));
  }, [siteMenu]);

  const headerRef = useRef<HTMLDivElement>(null);
  const headerContentRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = useCallback(
    (dropdownKey: HeaderDropdowns): void => {
      const updatedState: HeaderDropdownsState = expandedState[dropdownKey]
        ? { ...expandedState, [dropdownKey]: false }
        : keys<HeaderDropdowns>(expandedState).reduce((acc, key) => ({ ...acc, [key]: key === dropdownKey }), {
            ...expandedState,
          });

      setExpandedState(updatedState);
    },
    [expandedState],
  );

  const makeHeaderSticky = useCallback(() => {
    const isMdScreen: boolean = window.matchMedia(getMediaQuery('md')).matches;

    if (!isMdScreen) {
      return;
    }

    const { current: headerEl } = headerRef;
    const { current: headerContentEl } = headerContentRef;

    if (!window || !headerEl || !headerContentEl) {
      return;
    }

    if (window.scrollY > 20) {
      headerContentEl.classList.add('md:rounded-t-none');

      headerEl.classList.remove('md:top-4.5');
      headerEl.classList.remove('md:absolute');
    } else {
      headerEl.classList.add('md:top-4.5');
      headerEl.classList.add('md:absolute');

      headerContentEl.classList.remove('md:rounded-t-none');
    }
  }, []);

  useScroll(makeHeaderSticky);

  // required to set basic header styles in case of using route hash
  useEffect(() => {
    if (window) {
      makeHeaderSticky();
    }
  }, [makeHeaderSticky]);

  return (
    <div className="w-full">
      <div ref={headerRef} className="fixed top-0 z-50 w-full md:absolute md:top-4.5">
        <div
          ref={headerContentRef}
          className="container-base flex items-center justify-between space-x-4 bg-white px-4 shadow-lg md:rounded-lg md:py-4"
        >
          <Logo />

          <div className="w-full">
            <div className="flex items-center justify-end space-x-4">
              <Link href="/page/plan-your-visit" className="hidden md:block">
                <Button className="md:text-md lg:text-base-md xl:text-base-md" type="button">
                  {t('buttons.plan-visit')}
                </Button>
              </Link>

              <button type="button" className="lg:hidden" aria-label="Menu" title="Menu" onClick={toggleMenuOpen}>
                {Icons({ name: IconsTypes.MENU })}
              </button>

              <LocalesDropdown
                open={expandedState[DropdownCategories.LanguageSwitcher]}
                setOpen={() => toggleDropdown(DropdownCategories.LanguageSwitcher)}
                className="hidden lg:block"
              />
            </div>

            <HeaderMenu siteMenu={siteMenu} expandedState={expandedState} toggleDropdown={toggleDropdown} />
          </div>
        </div>
      </div>

      <DrawerMenu
        open={isMenuOpen}
        toggleOpen={toggleMenuOpen}
        siteMenu={siteMenu}
        expandedState={expandedState}
        toggleDropdown={toggleDropdown}
      />
    </div>
  );
};
