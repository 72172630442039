import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { FC } from 'react';

import { getImageType } from '@/helpers/seo';
import getLanguageAlternates from '@/helpers/seo/getLanguageAlternates';
import { ISEOOptions } from '@/types';

export const SEOBlock: FC<ISEOOptions> = ({ images = [], title, description, thumbnail }) => {
  const route = useRouter();
  const pageLink = process.env.SITE_DOMAIN + route.asPath.split('?')[0];
  const additionalMetaTags = [];

  if (thumbnail) {
    additionalMetaTags.push({
      name: 'thumbnail',
      content: thumbnail,
    });
  }

  return (
    <NextSeo
      title={title}
      description={description}
      canonical={pageLink}
      languageAlternates={getLanguageAlternates(pageLink)}
      additionalMetaTags={additionalMetaTags}
      openGraph={{
        title,
        description,
        url: pageLink,
        siteName: title,
        images: images.map(({ url, alt, width = 800, height = 600 }) => ({
          url,
          width,
          height,
          alt: alt ?? title,
          type: getImageType(url),
        })),
      }}
    />
  );
};
