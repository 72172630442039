import { Dispatch, SetStateAction, useCallback, useState } from 'react';

type ToggleValueFunction = () => void;
type SetValueFunction = Dispatch<SetStateAction<boolean>>;
type UseToggle = [boolean, ToggleValueFunction, SetValueFunction];

export const useToggle = (defaultValue?: boolean): UseToggle => {
  const [value, setValue] = useState<boolean>(defaultValue ?? false);

  const toggleValue = useCallback<ToggleValueFunction>(() => setValue((prevState) => !prevState), []);

  return [value, toggleValue, setValue];
};
