export const formatPathname = (pathname?: string | null): string => {
  if (!pathname) {
    return '';
  }

  return pathname?.startsWith('/') ? pathname : `/page/${pathname}`;
};

export const formatLink = ({ page_slug, blog_slug }: { page_slug: string, blog_slug: string }) => {
  if (page_slug) {
    return page_slug?.startsWith('/') ? page_slug : `/page/${page_slug}`;
  }
  return blog_slug?.startsWith('/') ? `/blog${blog_slug}` : `/blog/${blog_slug}`;
}