import { FC, HTMLAttributes } from 'react';

import { Dropdown } from '@/components/shared/Dropdown';
import { keys } from '@/helpers/common';
import { useLanguageSwitcher } from '@/hooks/useLanguageSwitcher';
import { LOCALES_LIST } from '@/hooks/useLanguageSwitcher';
import { Locale } from '@/types';

const localesKeys = keys<Locale>(LOCALES_LIST);

interface ILocalesDropdownProps extends HTMLAttributes<HTMLDivElement> {
  open: boolean;
  setOpen: () => void;
}

export const LocalesDropdown: FC<ILocalesDropdownProps> = ({ open, setOpen, className }) => {
  const { handleSwitchLocale, currentLocale, locales, createHref } = useLanguageSwitcher();

  return (
    <Dropdown
      open={open}
      setOpen={setOpen}
      switcher={currentLocale}
      className={className}
      dropdown={
        <ul className="rounded-sm bg-black-600 lg:bg-white">
          {localesKeys.map((locale) => (
            <li key={locale}>
              <a
                href={createHref(locale as Locale)}
                hrefLang={locale}
                className="px-4 py-3 text-base-md font-semibold"
                onClick={() => handleSwitchLocale(locale)}
              >
                {locales[locale]}
              </a>
            </li>
          ))}
        </ul>
      }
    />
  );
};
